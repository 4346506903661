.App {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.result {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form {
  margin-left: 15px;
}

.form-default {
  width: 400px;
  float: left;
}

.hidden {
  display: none;
}
